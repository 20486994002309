// import { useSelector, useDispatch } from "react-redux"
// import style from "./ConsultarNotas.module.css"
// import { getStudents } from "../../redux/actions";
// import { useEffect } from "react";
// import {Link} from "react-router-dom"
// import SearchBar from "../SearchBar/SearchBar";
// import NavBar from "../NavBar/NavBar";
// import NavBarSecretaria from "../NavBarSecretaria/NavBarSecretaria";
// import NavBarSuperior from "../NavBarSuperior/NavBarSuperior"



// const ConsultarNotas = ()=>{

//     const dispatch = useDispatch();
//     const estudiantes = useSelector((state)=>state.students)
//     const token = useSelector(state => state.token);
//     useEffect(()=>{
        
//         dispatch(getStudents())
//     },[])

//     return(
//         <div className={style.grid}>
//             <div>
//             {token?.role === 'Secretaria'?  <NavBarSecretaria/>: <NavBar/>}
//             </div>

//             <div>
//                 <div>
//                     <NavBarSuperior/>
//                 </div>
//                 <div className={style.container}>
//                 <h1>Consultar Notas</h1>
//             <SearchBar></SearchBar>
//             <br></br>
//             <div className={style.tablacontainer}>
//             <table className={style.tabla} >
//                     <thead>
//                         <tr>
//                             <th  className={style.tabla}>Id</th>
//                             <th  className={style.tabla}>Nombres</th>
//                             <th  className={style.tabla}>Apellidos</th>
//                             <th  className={style.tabla}>Genero</th>
//                             <th  className={style.tabla}>Email</th>
//                             <th  className={style.tabla}>Notas</th>
//                             {/* Agrega más encabezados de columna según tus datos */}
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {estudiantes.map(estudiantes => (
//                             <tr key={estudiantes.id_estudiante}>
//                                 <td className={style.encabezado}>{estudiantes.id_estudiante}</td>
//                                 <td className={style.encabezado}>{estudiantes.nombres}</td>
//                                 <td className={style.encabezado}>{estudiantes.apellidos}</td>
//                                 <td className={style.encabezado}>{estudiantes.genero}</td>
//                                 <td className={style.encabezado}>{estudiantes.email}</td>
//                                 <td className={style.encabezado}><Link to={`/estudiantenotas/${estudiantes.id_estudiante}`} >Consultar</Link></td>
//                                 {/* Agrega más celdas según tus datos */}
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>

//             </div>
//                 </div>
//             </div>
            

            
//         </div>
//     )
// }

// export default ConsultarNotas;













import { useSelector, useDispatch } from "react-redux";
import style from "./ConsultarNotas.module.css";
import { getStudents } from "../../redux/actions";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import NavBarSecretaria from "../NavBarSecretaria/NavBarSecretaria";
import NavBarSuperior from "../NavBarSuperior/NavBarSuperior";

const ConsultarNotas = () => {
    const dispatch = useDispatch();
    const estudiantes = useSelector((state) => state.students);
    const token = useSelector((state) => state.token);

    // State for search term and pagination
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const studentsPerPage = 7; // Number of students per page

    useEffect(() => {
        dispatch(getStudents()); // Fetch students on component mount
    }, [dispatch]);

    // Function to handle changes in the search input
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase()); // Convert to lowercase for case-insensitive search
        setCurrentPage(1); // Reset to first page on new search
    };

    // Filter students by last name
    const filteredStudents = estudiantes.filter((estudiante) =>
        estudiante.apellidos.toLowerCase().includes(searchTerm)
    );

    // Calculate current students based on pagination
    const indexOfLastStudent = currentPage * studentsPerPage;
    const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
    const currentStudents = filteredStudents.slice(indexOfFirstStudent, indexOfLastStudent);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Total number of pages
    const totalPages = Math.ceil(filteredStudents.length / studentsPerPage);

    // Logic to show only a few pages in pagination
    const maxPageNumbers = 5; // Maximum number of page buttons to show
    const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
    const endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);
    const pages = [];

    for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
    }

    return (
        <div className={style.grid}>
            <div>
                {token?.role === 'Secretaria' ? <NavBarSecretaria /> : <NavBar />}
            </div>

            <div>
                <div>
                    <NavBarSuperior />
                </div>
                <div className={style.container}>
                    <h1>Consultar Notas</h1>

                    {/* Search input */}
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Buscar por apellidos..."
                        className={style.searchInput}
                    />
                    <br />
                    <div className={style.tablacontainer}>
                        <table className={style.tabla}>
                            <thead>
                                <tr>
                                    <th className={style.tabla}>Id</th>
                                    <th className={style.tabla}>Nombres</th>
                                    <th className={style.tabla}>Apellidos</th>
                                    <th className={style.tabla}>Género</th>
                                    <th className={style.tabla}>Email</th>
                                    <th className={style.tabla}>Notas</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentStudents.length > 0 ? (
                                    currentStudents.map(estudiante => (
                                        <tr key={estudiante.id_estudiante}>
                                            <td className={style.encabezado}>{estudiante.id_estudiante}</td>
                                            <td className={style.encabezado}>{estudiante.nombres}</td>
                                            <td className={style.encabezado}>{estudiante.apellidos}</td>
                                            <td className={style.encabezado}>{estudiante.genero}</td>
                                            <td className={style.encabezado}>{estudiante.email}</td>
                                            <td className={style.encabezado}>
                                                <Link to={`/estudiantenotas/${estudiante.id_estudiante}`}>Consultar</Link>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6" className={style.noData}>No hay coincidencias</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    {/* Pagination */}
                    <div className={style.pagination}>
                        {currentPage > 1 && (
                            <button onClick={() => paginate(1)} className={style.pageButton}>
                                &laquo; Primera
                            </button>
                        )}
                        {currentPage > 1 && (
                            <button onClick={() => paginate(currentPage - 1)} className={style.pageButton}>
                                &lsaquo; Anterior
                            </button>
                        )}

                        {pages.map(number => (
                            <button
                                key={number}
                                onClick={() => paginate(number)}
                                className={`${style.pageButton} ${currentPage === number ? style.activePage : ""}`}
                            >
                                {number}
                            </button>
                        ))}

                        {currentPage < totalPages && (
                            <button onClick={() => paginate(currentPage + 1)} className={style.pageButton}>
                                Siguiente &rsaquo;
                            </button>
                        )}
                        {currentPage < totalPages && (
                            <button onClick={() => paginate(totalPages)} className={style.pageButton}>
                                Última &raquo;
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConsultarNotas;
