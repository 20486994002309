// import { useSelector, useDispatch } from "react-redux"
// import style from "./ListarEstudiantes.module.css"
// import { getStudents } from "../../redux/actions";
// import { useEffect } from "react";
// import {Link} from "react-router-dom"
// import SearchBar from "../SearchBar/SearchBar";
// import NavBar from "../NavBar/NavBar"
// import NavBarSuperior from "../NavBarSuperior/NavBarSuperior";

 

// const ListarEstudiantes = ()=>{ 

//     const dispatch = useDispatch();
//     const estudiantes = useSelector((state)=>state.students)

//     useEffect(()=>{
        
//         dispatch(getStudents())
//     },[])

//     return(
//         <div className={style.grid}>
//             <div>
//                 <NavBar />
//             </div>


//             <div>
//                 <div>
//                     <NavBarSuperior />
//                 </div>
//                 <div className={style.container}>
//                     <h1>Estudiantes</h1>
//                     <SearchBar></SearchBar>
//                     <br></br>
//                     <div className={style.tablacontainer}>
//                         <table className={style.tabla} >
//                             <thead>
//                                 <tr>
//                                     <th className={style.tabla}>Id</th>
//                                     <th className={style.tabla}>Nombres</th>
//                                     <th className={style.tabla}>Apellidos</th>
//                                     <th className={style.tabla}>Genero</th>
//                                     <th className={style.tabla}>Email</th>
//                                     <th className={style.tabla}>Certificado</th>
//                                     {/* Agrega más encabezados de columna según tus datos */}
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {estudiantes.map(estudiantes => (
//                                     <tr key={estudiantes.id_estudiante}>
//                                         <td className={style.encabezado}>{estudiantes.id_estudiante}</td>
//                                         <td className={style.encabezado}>{estudiantes.nombres}</td>
//                                         <td className={style.encabezado}>{estudiantes.apellidos}</td>
//                                         <td className={style.encabezado}>{estudiantes.genero}</td>
//                                         <td className={style.encabezado}>{estudiantes.email}</td>
//                                         <td className={style.encabezado}><Link to={`/generarcertificado/${estudiantes.id_estudiante}`} >Generar</Link></td>
//                                         {/* Agrega más celdas según tus datos */}
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </table>

//                     </div>
//                 </div>

//             </div>



//         </div>
//     )
// }

// export default ListarEstudiantes;









import { useSelector, useDispatch } from "react-redux";
import style from "./ListarEstudiantes.module.css";
import { getStudents } from "../../redux/actions";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import NavBarSuperior from "../NavBarSuperior/NavBarSuperior";

const ListarEstudiantes = () => {
    const dispatch = useDispatch();
    const estudiantes = useSelector((state) => state.students);

    // Estado local para el término de búsqueda
    const [searchTerm, setSearchTerm] = useState("");
    
    // Estado para la paginación
    const [currentPage, setCurrentPage] = useState(1);
    const studentsPerPage = 7; // Número de estudiantes por página

    useEffect(() => {
        dispatch(getStudents()); // Traemos los estudiantes al montar el componente
    }, [dispatch]);

    // Función para manejar el cambio en el campo de búsqueda
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase()); // Convertimos a minúsculas para hacer la búsqueda case-insensitive
        setCurrentPage(1); // Resetear a la primera página al realizar una nueva búsqueda
    };

    // Filtramos los estudiantes por apellidos según el término de búsqueda
    const filteredStudents = estudiantes.filter(estudiante =>
        estudiante.apellidos.toLowerCase().includes(searchTerm)
    );

    // Calcular los estudiantes que se muestran en la página actual
    const indexOfLastStudent = currentPage * studentsPerPage;
    const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
    const currentStudents = filteredStudents.slice(indexOfFirstStudent, indexOfLastStudent);

    // Cambiar de página
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Número total de páginas
    const totalPages = Math.ceil(filteredStudents.length / studentsPerPage);

    // Lógica para mostrar solo algunas páginas en el paginado
    const maxPageNumbers = 5; // Número máximo de botones de página a mostrar
    const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
    const endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);
    const pages = [];

    for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
    }

    return (
        <div className={style.grid}>
            <div>
                <NavBar />
            </div>

            <div>
                <div>
                    <NavBarSuperior />
                </div>
                <div className={style.container}>
                    <h1>Estudiantes</h1>

                    {/* Campo de búsqueda */}
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange} // Actualizamos el estado con cada cambio
                        placeholder="Buscar por apellidos..."
                        className={style.searchInput}
                    />

                    <br />
                    <div className={style.tablacontainer}>
                        <table className={style.tabla}>
                            <thead>
                                <tr>
                                    <th className={style.tabla}>Id</th>
                                    <th className={style.tabla}>Nombres</th>
                                    <th className={style.tabla}>Apellidos</th>
                                    <th className={style.tabla}>Género</th>
                                    <th className={style.tabla}>Email</th>
                                    <th className={style.tabla}>Certificado</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentStudents.length > 0 ? (
                                    currentStudents.map(estudiante => (
                                        <tr key={estudiante.id_estudiante}>
                                            <td className={style.encabezado}>{estudiante.id_estudiante}</td>
                                            <td className={style.encabezado}>{estudiante.nombres}</td>
                                            <td className={style.encabezado}>{estudiante.apellidos}</td>
                                            <td className={style.encabezado}>{estudiante.genero}</td>
                                            <td className={style.encabezado}>{estudiante.email}</td>
                                            <td className={style.encabezado}>
                                                <Link to={`/generarcertificado/${estudiante.id_estudiante}`}>Generar</Link>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6" className={style.noData}>No hay coincidencias</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                       
                    </div>
                     {/* Paginación */}
                     <div className={style.pagination}>
                            {currentPage > 1 && (
                                <button onClick={() => paginate(1)} className={style.pageButton}>
                                    &laquo; Primera
                                </button>
                            )}
                            {currentPage > 1 && (
                                <button onClick={() => paginate(currentPage - 1)} className={style.pageButton}>
                                    &lsaquo; Anterior
                                </button>
                            )}

                            {pages.map(number => (
                                <button
                                    key={number}
                                    onClick={() => paginate(number)}
                                    className={`${style.pageButton} ${currentPage === number ? style.activePage : ""}`}
                                >
                                    {number}
                                </button>
                            ))}

                            {currentPage < totalPages && (
                                <button onClick={() => paginate(currentPage + 1)} className={style.pageButton}>
                                    Siguiente &rsaquo;
                                </button>
                            )}
                            {currentPage < totalPages && (
                                <button onClick={() => paginate(totalPages)} className={style.pageButton}>
                                    Última &raquo;
                                </button>
                            )}
                        </div>
                </div>
            </div>
        </div>
    );
};

export default ListarEstudiantes;


